import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Comment from "../../../objects/Comment.js";
import EditCommentOverlay from "./EditCommentOverlay.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import User from "../../../objects/User.js";
import Button from "../../Button.js";
import EVAEvent from "../../EVAEvent.js";
import CommentDetailBox from "./CommentDetailBox.js";
import HistoryList from "../history/HistoryList.js";
import OverlayBox from "../../OverlayBox.js";

class CommentList extends SortableListBoxGroup{

	constructor(config) {
		super({
			isDivTable: true
		});
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		
		this.contentHeadline.setHeadlineById(Comment.OBJECT_DESCRIPTION_ID);
		
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			new EditCommentOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.pushRow(event.data, true);
					}
				},
				objectTypeId: scope.objectTypeId,
				objectId: scope.objectId
			});
		}, {});

		this.downloadButton = Button.createDownloadCsvButton();
		this.contentHeadline.append(this.downloadButton);
		this.downloadButton.click(function(event) {
			window.location.assign(Comment.API_ENDPOINT + scope.objectTypeId + '/comments' + scope.objectId + '.csv');
		});
		
		this.sortBy("date");

		this.load();
	}
	
	load() {
		let scope = this;
		Comment.loadAll(this.objectTypeId, this.objectId, function(commentList){
					scope.rowDatas.pushAll(commentList);
				}
			);
	}
	
	renderHead() {
		//no head;
	}
	
	renderRow(entry){
		let contentBox = this.table.newRow();
		let editButton = Button.createEditButton();
		contentBox.append(editButton);
		let scope = this;
		editButton.click(function(event){
			new EditCommentOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.rerender();
					}
				},
				objectTypeId: scope.objectTypeId,
				objectId: scope.objectId,
				comment: entry
			});
		}, {});
		let historyButton = Button.createHistoryButton();
		contentBox.append(historyButton);
		historyButton.click(function(event){
			let historyList = new HistoryList({
				objectTypeId: Comment.OBJECT_DESCRIPTION_ID,
				objectId: entry.id,
				elementRenderer: function(comment) {
					return new CommentDetailBox({comment:comment})
				}
			});
		}, {});
		let commentDetailBox = new CommentDetailBox({comment:entry});
		contentBox.append(commentDetailBox);
	}
}
export default CommentList;