import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import Meter from "../../../objects/Meter.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";

class EditMeterOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.meter = config.meter;
			this.meterPoint = config.meterPoint;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);
		this.numberInputField = table.addInput(Meter.METER_NUMBER_FIELD_DESCRIPTION_ID, 'meter_nr');
		this.factorInputField = table.addNumberInput(Meter.METER_FACTOR_FIELD_DESCRIPTION_ID, "meter_faktor");
		this.readInputField = table.addBooleanInput(Meter.READ_FIELD_DESCRIPTION_ID, "meter_ablesen");
		this.mediumUnitsInputField = table.addSelect(Meter.UNIT_FIELD_DESCRIPTION_ID, "meter_einheitMediumID");
		if (this.meterPoint) {
			this.setMediumUnits();
		} else {
			this.meter.getMeterPoint(function(meterPoint){
				scope.meterPoint = meterPoint;
				scope.setMediumUnits();
			});
		}
		if (this.meter) {
			this.numberInputField.val(this.meter.nr);
			this.factorInputField.val(this.meter.faktor);
			this.readInputField.val(this.meter.ablesen);
		}
		
		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.meter == null) {
				scope.meter = new Meter();
				scope.meter.messpunktID = scope.meterPoint.id;
			}
			scope.meter.nr = scope.numberInputField.val();
			scope.meter.faktor = scope.factorInputField.val();
			scope.meter.ablesen = scope.readInputField.val();
			scope.meter.einheitMediumID = scope.mediumUnitsInputField.val();
			scope.meter.upsert(function(meter){
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: meter
				});
				scope.notify(event);
				scope.overlayBox.remove();
			});
		});
	}
	
	setMediumUnits() {
		let scope = this;
		this.meterPoint.getMedium(function(medium){
			medium.getAllUnits(function(mediumUnits){
				let arrayLength = mediumUnits.length;
				for (let i = 0; i < arrayLength; i++) {
					let mediumUnit = mediumUnits[i];
					let option = scope.mediumUnitsInputField.addOption(mediumUnit.id, mediumUnit.name);
					if (scope.meter != null && scope.meter.einheitMediumID == mediumUnit.id) {
						option.setSelected(true);
					}
				}
			});
		});
	}
}
export default EditMeterOverlay;