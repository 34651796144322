import Objects from "./Objects.js";
import Link from "../ui/content/Link.js";
class Standort {
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static loadAll(callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let standorte = [];
			for (let i = 0; i < arrayLength; i++) {
				standorte.push(new Standort(data[i]));
			}
			callback(standorte);
		}, Standort.API_ENDPOINT + "all");
	}
	
	static load(standortId, callback){
		Objects.load(function(data){
			callback(new Standort(data));
		}, Standort.API_ENDPOINT + standortId);
	}
	
	static remove(standortId, callback){
		Objects.remove(callback, Standort.API_ENDPOINT + standortId);
	}
	
	getMonthlyElectricityConsumption(callback){
		if (this.monthlyPowerConsumptionTemp != null) {
			callback(this.monthlyPowerConsumptionTemp);
		}
		var scope = this;
		this.getMonthlyConsumption(function(data){
			scope.monthlyPowerConsumptionTemp = data;
			callback(data);
		}, "electricity");
	}
	
	getDisplayText() {
		return this.name + ", " + this.strasse + ", " + this.plz + " " + this.ort;
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Standort/page.jsp?standortID="+this.id
	}
	
	getLink(){
		let link = new Link({
			href: this.getURL(),
			text: this.getDisplayText()
		});
		return link;
	}
	
	getMonthlyHeatConsumption(callback){
		if (this.monthlyHeatConsumptionTemp != null) {
			callback(this.monthlyHeatConsumptionTemp);
		}
		var scope = this;
		this.getMonthlyConsumption(function(data){
			scope.monthlyHeatConsumptionTemp = data;
			callback(data);
		}, "heat");
	}
	
	getMonthlyWaterConsumption(callback){
		if (this.monthlyWaterConsumptionTemp != null) {
			callback(this.monthlyWaterConsumptionTemp);
		}
		var scope = this;
		this.getMonthlyConsumption(function(data){
			scope.monthlyWaterConsumptionTemp = data;
			callback(data);
		}, "water");
	}
	
	getMonthlyConsumption(callback, type){
		Objects.load(function(data){
			let graphData = [];
			let arrayLength = data.length;
			for (let i = 0; i < arrayLength; i++) {
				let year = data[i];
				let yearGraph = [];
				graphData.push(yearGraph);
				let monthLength = year.months.length;
				for (let j = 0; j < monthLength; j++) {
					let month = year.months[j];
					let monthGraph = [];
					yearGraph.push(monthGraph);
					monthGraph[0] = month.month + 1;
					monthGraph[1] = month.data;
				}
			}
			callback(graphData);
		}, "/api/Standort/monthlyConsumption?id="+this.id+"&type=" + type);
	}
	
	getYearlyElectricityConsumption(callback){
		if (this.yearlyPowerConsumptionTemp != null) {
			callback(this.yearlyPowerConsumptionTemp);
		}
		var scope = this;
		this.getYearlyConsumption(function(data){
			scope.yearlyPowerConsumptionTemp = data;
			callback(data);
		}, "electricity");
	}
	
	getYearlyHeatConsumption(callback){
		if (this.yearlyHeatConsumptionTemp != null) {
			callback(this.yearlyHeatConsumptionTemp);
		}
		var scope = this;
		this.getYearlyConsumption(function(data){
			scope.yearlyHeatConsumptionTemp = data;
			callback(data);
		}, "heat");
	}
	
	getYearlyWaterConsumption(callback) {
		if (this.yearlyWaterConsumptionTemp != null) {
			callback(this.yearlyWaterConsumptionTemp);
		}
		var scope = this;
		this.getYearlyConsumption(function(data){
			scope.yearlyWaterConsumptionTemp = data;
			callback(data);
		}, "water");
	}
	
	getYearlyConsumption(callback, type) {
		Objects.load(function(data){
			let graphData = [];
			let consumptionData = [];
			graphData.push(consumptionData);
			let arrayLength = data.consumption.length;
			for (let i = 0; i < arrayLength; i++) {
				let consumption = [];
				consumptionData.push(consumption);
				consumption[0] = data.consumption[i].lable;
				consumption[1] = data.consumption[i].value;
			}
			let analysisBaseData = [];
			graphData.push(analysisBaseData);
			arrayLength = data.analysisBase.length;
			for (let i = 0; i < arrayLength; i++) {
				let analysisBase = [];
				analysisBaseData.push(analysisBase);
				analysisBase[0] = data.analysisBase[i].lable;
				analysisBase[1] = data.analysisBase[i].value;
			}
			callback(graphData);
		}, "/api/Standort/yearlyConsumption?id="+this.id+"&type=" + type);
	}
	
	getYearlyElectricityConsumptionSpecific(callback){
		if (this.yearlyPowerConsumptionSpecificTemp != null) {
			callback(this.yearlyPowerConsumptionSpecificTemp);
		}
		var scope = this;
		this.getYearlyConsumptionSpecific(function(data){
			scope.yearlyPowerConsumptionSpecificTemp = data;
			callback(data);
		}, "electricity");
	}
	
	getYearlyHeatConsumptionSpecific(callback){
		if (this.yearlyHeatConsumptionSpecificTemp != null) {
			callback(this.yearlyHeatConsumptionSpecificTemp);
		}
		var scope = this;
		this.getYearlyConsumptionSpecific(function(data){
			scope.yearlyHeatConsumptionSpecificTemp = data;
			callback(data);
		}, "heat");
	}
	
	getYearlyWaterConsumptionSpecific(callback) {
		if (this.yearlyWaterConsumptionSpecificTemp != null) {
			callback(this.yearlyWaterConsumptionSpecificTemp);
		}
		var scope = this;
		this.getYearlyConsumptionSpecific(function(data){
			scope.yearlyWaterConsumptionSpecificTemp = data;
			callback(data);
		}, "water");
	}
	
	getYearlyConsumptionSpecific(callback, type) {
		Objects.load(function(data){
			let graphData = [];
			let consumptionData = [];
			graphData.push(consumptionData);
			let arrayLength = data.consumption.length;
			for (let i = 0; i < arrayLength; i++) {
				let consumption = [];
				consumptionData.push(consumption);
				consumption[0] = data.consumption[i].lable;
				consumption[1] = data.consumption[i].value;
			}
			let analysisBaseData = [];
			graphData.push(analysisBaseData);
			arrayLength = data.analysisBase.length;
			for (let i = 0; i < arrayLength; i++) {
				let analysisBase = [];
				analysisBaseData.push(analysisBase);
				analysisBase[0] = data.analysisBase[i].lable;
				analysisBase[1] = data.analysisBase[i].value;
			}
			callback(graphData);
		}, "/api/Standort/yearlyConsumptionSpecific?id="+this.id+"&type=" + type);
	}
}
Standort.API_ENDPOINT = "/api/Standort/";
Standort.DESCRIPTON_ID = 17;
Standort.FIELD_NAME_ID = 9;
Standort.FIELD_STREET_ID = 10;
Standort.FIELD_ZIP_ID = 11;
Standort.FIELD_REGION_ID = 12;
Standort.FIELD_START_ID = 15;
Standort.FIELD_END_ID = 16;
Standort.FIELD_COMMENT_ID = 13;
Standort.FIELD_GTZ_STATION_ID = 14;
export default Standort;